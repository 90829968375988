module.exports={
"label.account.name":`Nome`,
"label.account.copy":`Copia il codice di verifica casuale presente nell'email e clicca sul link per cambiare la password nel passo successivo.`,
"label.account.phone.length":`Il numero di cellulare richiede almeno 7 caratteri`,
"label.account.Start.trial":`OTTENERE STRATO`,
"label.account.employees1000":`Più di 1000 dipendenti`,
"label.account.other.Ways":`Altri metodi di accesso`,
"label.account.emailsync.button.cancel":`Cancella`,
"label.account.rule":`Consiste di 816 cifre e lettere.`,
"label.account.password.update.success":`Password cambiata con successo.`,
"label.account.describe":`CloudCC si concentra sulla personalizzazione digitale, l'intelligenza analitica e la connettività globale, ed è personalizzato su richiesta insieme allo sviluppo del business aziendale.`,
"label.account.enter.Emailbox":`Inserisci il tuo indirizzo email.`,
"label.account.password":`Password`,
"label.account.problem":`Accesso fallito.`,
"label.account.Complete.reset":`Email. Inserisci il codice di verifica per completare la reimpostazione della password.`,
"label.account.crm.used":`CRM utilizzato da società quotate in borsa`,
"label.account.report.loading":``,
"label.account.notcustomer":`Nessun conto?`,
"label.account.employees501":`5011000 dipendenti`,
"label.account.title.operationManager":`Direttore delle operazioni`,
"label.account.Preset":`Strumenti di gestione del progetto preimpostati`,
"label.account.company":`Azienda`,
"label.account.come":`Benvenuti nella piattaforma Cloudcc per il successo dei clienti. La nostra nuova piattaforma lightning vi offre la soluzione più veloce e completa, permettendovi di fare tutto con il vostro cliente al centro.  `,
"label.account.input.password":`Inserisci la tua password.`,
"label.account.emailobject.emaildetail.button.back":`Indietro`,
"label.account.title.itManager":`Responsabile IT`,
"label.account.Modified.successfully":`La password è stata cambiata con successo. Clicca di nuovo su login per tornare alla pagina di login.`,
"label.account.only11":`Si prega di inserire almeno 7 cifre.`,
"label.account.signin":`Accesso`,
"label.account.register":`Registrati per ottenere`,
"label.account.adopt":`Verifica superata!`,
"label.account.title.hrManager":`Responsabile Marketing/Risorse`,
"label.account.input.usernames":`Inserisci il tuo nome utente.`,
"label.account.only.Numbers":`Il numero di telefono può inserire solo numeri.`,
"label.account.title.salesManager":`Responsabile delle vendite`,
"label.account.email":`Email`,
"label.account.click":`Fare clic su`,
"label.account.and":`e`,
"label.account.mobile":`Telefono`,
"label.account.Privacy.policy":`Politica sulla privacy`,
"label.account.password.isnull":`La password non può essere vuota.`,
"label.account.employees101":`101500 dipendenti`,
"label.account.remember.name":`Ricorda il nome utente`,
"label.account.Select.country":`Seleziona il tuo paese/regione.`,
"label.account.distinguish":`La password è sensibile alle maiuscole e alle minuscole.`,
"label.account.fillMobile":`Inserisci il tuo numero di telefono.`,
"label.account.Preconfigured":`Rapporti e cruscotti preimpostati`,
"label.account.Select.job":`Per favore, seleziona la tua posizione.`,
"label.account.employees21":`21100 dipendenti`,
"label.account.username":`Nome utente`,
"label.account.Submit":`Invia`,
"label.account.confirm":`Conferma dell'account`,
"label.account.contact":`Contattateci`,
"label.account.country":`Paese`,
"label.account.username.isnull":`Il nome utente non può essere vuoto.`,
"label.account.forget.password":`Hai dimenticato la password?`,
"label.account.Loading":`Caricamento`,
"label.account.goon":`Continua`,
"label.account.Register.trial":`Iscriviti per una prova gratuita`,
"label.account.title.other":`Altro`,
"label.account.surname":`Nome`,
"label.account.Fail":`Verifica non riuscita, per favore riprova.`,
"label.account.Terms.service":`Condizioni di servizio`,
"label.account.Select.staff":`Si prega di selezionare il numero di dipendenti.`,
"label.account.username.enter":`Inserisci il tuo cognome.`,
"label.account.trial":`Prova gratuita`,
"label.account.employees1":`120 dipendenti`,
"label.account.Quick.registration":`o accedere rapidamente`,
"label.account.tabpage.ok":`Comfirm`,
"label.account.normal.reregister":`Accedi di nuovo`,
"label.account.batchadd.newbatchpage.save.em":`Il formato dell'email non è corretto.`,
"label.account..user.title":`Titolo di lavoro`,
"label.account.No.credit.card.required":`Nessuna carta di credito richiesta.`,
"label.account.enterprise.Wechat":`WeCom`,
"label.account.sample.data":`Precaricare i dati del campione`,
"label.account.title.hobbies":`Hobby`,
"label.account.platform":`Nuova piattaforma CRM intelligente, mobile e digitale`,
"label.account.sent":`Email inviata`,
"label.account.Confirm.password":`Per favore, conferma la tua password.`,
"label.account.fill":`Si prega di compilare tutti i campi.`,
"label.account.user.resetpw":`Ripristina la password`,
"label.account.Spell":`Trascina il cursore per completare il puzzle.`,
"label.account.identifying.code":`Si prega di inserire il codice di verifica.`,
"label.account.staff":`Dipendenti`,
"label.account.welcome":`Benvenuti alla CloudCC`,
"label.account.online.guidance":`Consulenza e istruzione online`,
"label.account.login.CloudCC":`Accesso a CloudCC`,
"label.account.Company.name":`Inserisci il nome della tua azienda.`,
"label.account.title.generalManager":`Direttore Generale`,
"label.account.enter.user":`Per reimpostare la tua password, inserisci il tuo nome utente CloudCC.`,
"label.account.sent.code":`Il codice di verifica è stato inviato a`,
"label.account.pw.error.text":`Le due password che hai inserito non corrispondono.`,
"label.account.title.customerManager":`Responsabile del servizio clienti`,
"label.account.label.captcha":`Codice di verifica`,
"label.account.Trial.days":`Iscriviti per 30 giorni di prova gratuita`,
"label.account.please.enter.name":`Inserisci il tuo nome.`,
"label.account.inspect":`Se non ricevi l'email di conferma del codice di verifica, controlla lo spam.`,
"label.account.User.rule":`Il nome utente è sotto forma di un indirizzo email.`,
"label.account.go.Login":`Hai già un account?`,
"label.account.Reading.consent":`Sono d'accordo con il`,
"label.account.Change.Password2":`Cambiare la password`,
"label.account.Loginby":`Accesso da`,
"label.account.apply.partner":`Fai domanda per diventare un partner CloudCC`,
"label.account.MobileLogin":`Mobile`,
"label.account.Registration":``,
"label.account.Italy":`Italia`,
"label.account.view.details":`Vedi dettaglio`,
"label.account.accept":`Si prega di leggere e accettare i Termini di servizio e l'Informativa sulla privacy`,
"label.account.China.Mainland":`Cina continentale`,
"label.account.NewPassword":`Inserisci la nuova password`,
"label.account.Mobile.not.empty":`Il numero di cellulare non può essere vuoto`,
"label.account.Please.select":`Si prega di scegliere`,
"label.account.market.introduce":`La piattaforma unificata dei canali di marketing ti aiuta a conquistare più lead`,
"label.account.IOS":`Scansione per scaricare l'applicazione iOS`,
"label.account.failsend":`Fallito l'invio`,
"label.account.UpperLowerlAphanumeric.character":`Sono richieste lettere maiuscole e minuscole, numeri e simboli`,
"label.account.New.Password1":`Nuova password`,
"label.account.succSend":`Ti è stata inviata un'email. Per favore, copia il codice di verifica nell'email e reimposta la password.`,
"label.account.Account.mobile":`Numero di conto o di cellulare`,
"label.account.Login.consent":`Effettuando il login, l'utente accetta di`,
"label.account.fillAreaCode":`Per favore, inserisci il codice di zona`,
"label.account.active.login":``,
"label.account.jp":`Giappone`,
"label.account.partner.success1":`La registrazione come partner CloudCC è avvenuta con successo.`,
"label.account.Reset":`Azzerare`,
"label.account.no.user.info":``,
"label.account.EnterOldPass":`Inserisci la vecchia password`,
"label.account.Germany":`Germania`,
"label.account.title.content":`Iscriviti a CloudCC | 30 giorni di prova gratuita  Prova ora!`,
"label.account.Taiwan":`Taiwan, Cina`,
"label.account.China.Station":`Cina`,
"label.account.sale.introduce":`L'automazione delle vendite ti aiuta a vincere più affari`,
"label.account.Back":`Passo precedente`,
"label.account.market":`Nuvola di marketing`,
"label.account.section.company":`Dettagli dell'azienda`,
"label.account.check.email":`il nostro account executive ti contatterà tra un po', per favore controlla la tua email in tempo`,
"label.account.characters":`5 caratteri`,
"label.account.International":`Internazionale`,
"label.account.britain":`Regno Unito`,
"label.account.reg.fail.msg":`L'iscrizione non è riuscita. Riprovare più tardi.`,
"label.account.success.reg":`ti sei registrato con successo`,
"label.account.company.url":`Sito web`,
"label.account.CannotBeEmpty":`Non può essere vuoto`,
"label.account.phone.note":``,
"label.account.second":`{0} secondi dopo`,
"label.account.Change.Password":`Cambia la tua password`,
"label.account.Netherlands":`Paesi Bassi`,
"label.account.pleaseAgree":`Si prega di accettare e acconsentire ai Termini di servizio e all'Informativa sulla privacy`,
"label.account.copyright":`CloudCC Inc. Tutti i diritti riservati`,
"label.account.letter":`1 lettera`,
"label.account.remember":`Ora mi ricordo`,
"label.account.accountNum":`Conto`,
"label.account.code.not.empty":`Il captcha non può essere vuoto`,
"label.account.Spain":`Spagna`,
"label.account.Alphanumeric":`È richiesto un mix di lettere e numeri`,
"label.account.obtain":`Acquisire`,
"label.account.company.txt":`Aggiungi il tuo nome, se non hai un'entità commerciale registrata`,
"label.account.con.browser":``,
"label.account.Android":`Scansione per scaricare l'applicazione Android`,
"label.account.title":`Prova gratuita di CloudCC  CloudCC.com`,
"label.account.partner.success2":`Ci metteremo in contatto con voi tra qualche tempo.`,
"label.account.Macao":`Macao, Cina`,
"label.account.Brazil":`Brasile`,
"label.account.service.introduce":`Il processo di servizio senza soluzione di continuità migliora l'efficacia della risposta`,
"label.account.codeFailure":`Codice di verifica non valido`,
"label.account.AccountLogin":`Conto`,
"label.account.pswComplexReqRules":`La lunghezza minima della password è {0}, e {1}`,
"label.account.UpperLowerlAphanumeric":`Sono richieste lettere maiuscole e minuscole e numeri`,
"label.account.active.username":``,
"label.account.rememberPassword":`Ricorda la password`,
"label.account.HongKong":`Hon Kong, Cina`,
"label.account.oldPass":`Vecchia password`,
"label.account.Data.storage":`I tuoi dati saranno conservati nel centro dati {0}.`,
"label.account.number":`1 numero`,
"label.account.Poland":`Polonia`,
"label.account.service":`Nuvola di servizio`,
"label.account.Incorrect.format":`Formato o numero errato, reinserire`,
"label.account.remember.status":`Ricordati di me`,
"label.account.sale":`Nuvola delle vendite`,
"label.account.No.account.binding":`Nessun account è vincolato. Si prega di contattare l'amministratore`,
"label.account.Russia":`Russia`,
"label.account.France":`Francia`,
"label.account.partner.content":`CloudCC è un'azienda globale che ora sta reclutando partner per esplorare soluzioni in tutti i settori. Vieni a far parte del nostro programma di affiliazione!`,
"label.account.reg.phone.already":`Questa organizzazione esiste già per questo telefono. Effettuare il login o utilizzare un altro telefono per iscriversi.`,
"label.account.Confirm":`Conferma la nuova password`,
"label.account.VerificationLogin":`Verificare il codice`,
"label.account.USA":`Stati Uniti`,
"label.account.mobile.num":`Inserisci il numero di cellulare`,
"label.account.MinLength":`La lunghezza minima della password è`,
"label.account.change.your.password":`Si prega di cambiare la password ora in vista della sicurezza`,
"label.account.new.password":`Inserisci una nuova password per {0}. Assicurati di includere almeno:`,
"label.account.website.enter":`Inserire l'indirizzo del sito web ufficiale`,
"label.account.pswComplexIsZero":``
}